<template>

	<div class="cont sales" id="p18">
		<div class="page-title">{{ program.name }}</div>

		<div class="c01">
			<div class="left">
				<div class="s01">
					<div class="c-item">
						<div class="title">상태</div>
						<div class="content">
							<select name="" id=""
								v-model="search.status"
								@change="getData"
							>
								<option 
									v-for="(settlement, index) in codes.merchants.status"
									:key="index"
									:value="settlement.code"
								>{{ settlement.codeName }}</option>
							</select>
						</div>
					</div>
					<div class="c-item">
						<div class="title">검색어</div>
						<div class="content">
							<input type="text" placeholder="영업점명을 입력하세요"
								v-model="search.keyword"
							/>
						</div>
					</div>
				</div>
				<div class="s02">
					<a @click="getData"><div class="btn-search">조회</div></a>
					<a @click="clear"><div class="btn-reset">초기화</div></a>
				</div>
			</div>
		</div>

		<div class="c02">
			<div class="table type07">
				<div class="text-right mb-10">
					
					<v-btn class="primary" tile style="height: 34px; padding: 0 20px;"
						@click="showNew"
					>영업점 추가 <v-icon small>mdi-plus</v-icon></v-btn>
				</div>
				<div class="item-header">
					<div class="item">
						<div class="td">No.</div>
						<div class="td">영업 담당자</div>
						<div class="td">ID</div>
						<div class="td">비밀번호</div>
						<div class="td">연락처</div>
						<div class="td">가입일</div>
						<div class="td">상태</div>
						<div class="td">매출내역</div>
					</div>
				</div>
				<div class="item-list">
					<div class="item"
						v-for="(item, index) in items"
						:key="index">
						<div class="td">{{ item.rowNumber}}</div>
						<div class="td">{{ item.salesName}}</div>
						<div class="td">{{ item.userId }}</div>
						<div class="td">
							<v-btn class="success2" small
								v-if="item.status != 'Waiting'"
								@click="showPassword(index)"
							>임시 비밀번호 <v-icon>mdi-menu-right</v-icon></v-btn>
						</div>
						<div class="td">{{ item.contact | makeTell }}</div>
						<div class="td">{{ item.joinDate | transDate }}</div>
						<div class="td">
							<v-btn small
								v-if="item.status != 'Waiting'"
								:class="item.status == 'Normal' ? 'success2' : ( item.status == 'Pause' ? 'warning' : 'danger')"
								@click="showAccount(index)"
							>{{ item.statusName }} <v-icon>mdi-menu-right</v-icon></v-btn>
							<v-btn
								class="default" small
								v-if="item.status == 'Waiting'"
							>{{ item.statusName }} </v-btn>
						</div>
						<div class="td">
							<v-btn class="info " small
								v-if="item.status != 'Waiting'"
							>매출현황<v-icon>mdi-menu-right</v-icon></v-btn>
							
							<v-btn class="orange" small
								v-if="item.status == 'Waiting'"
								v-clipboard="'abb'"
								v-clipboard:success="clipboardSuccessHandler"
								v-clipboard:error="clipboardErrorHandler"
							>회원가입 링크 <v-icon x-small>mdi-content-copy</v-icon></v-btn>
						</div>
					</div>
				</div>
				<div
					v-if="items.length <= 0"
					class="no-data"
				>
					No Data
				</div>
				<div class="text-center mt-10">
					<Pagination
						:options="search"
					/>
				</div>
			</div>
		</div>

		<SalesPopupPassword
			v-if="showPopup.item"
			:item="item"
			
			@setOverlay="setOverlay"
			@setNotify="setNotify"
		></SalesPopupPassword>

		<SalesPopupAccount
			v-if="showPopup.account"
			:item="item"
			
			@setOverlay="setOverlay"
			@setNotify="setNotify"
			@setAccount="setAccount"
		></SalesPopupAccount>

		<SalesPopupNew
			v-if="showPopup.new"
			:item="item"
			
			@setOverlay="setOverlay"
			@setNotify="setNotify"
		></SalesPopupNew>
	</div>
</template>

<script>

	import { Axios } from '@/resources/axios/axios'
	import { date } from '@/resources/date/date'
	import { filter } from '@/resources/filter/filter'
	import { columns } from '@/resources/columns/columns'
	
	import Pagination from '@/components/Pagination'
	
	import SalesPopupPassword from '@/view/Sales/SalesPopupPassword'
	import SalesPopupAccount from '@/view/Sales/SalesPopupAccount'
	import SalesPopupNew from '@/view/Sales/SalesPopupNew'

	export default {
		name: 'PaymentsHistory'
		,props: ['user', 'codes']
		,components: { Pagination, SalesPopupPassword, SalesPopupAccount, SalesPopupNew }
		,data: function(){
			return {
				program: {
					name: this.$language.menu[2].list[0].name
				}
				,agentSeq: this.user.basic.userSeq
				,items: [
				]
				,details: [
				]
				,columns: columns.Merchant.settlement
				,item: {
				}
				,search_default: {
					keyword: ''
					,status: ''
				}
				,search: {
					
				}
				,filter: filter
				,settlementStatus: this.codes.settlementStatus
				,datePicker: {
					start: {
						view: false
					}
					,end: {
						view: false
					}
				}
				,showPopup: {
					item: false
					,account: false
					,new: false
				}
				,today: date.getToday()
				,dateType: 'd'
				,paymentItem: {}
			}
		}
		,computed: {
			excel_items: function(){
				return this.items.filter(function(item){
				
					item.statusName = item.statsu == 'Y' ? '완료' : '대기'
					
					return item
				})
			}
		}
		,methods: {
			getData: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/branch/history'
						,data: this.search
						,authorize: true
					})
					
					if(result.success){
						this.items = result.data.content.content
						
						this.$set(this.search, 'total_page', result.data.content.totalPages)
						this.$set(this.search, 'total_count', result.data.content.totalElements)
						this.$set(this.search, 'list_cnt', result.data.content.size)
						
						//await this.excel()
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,goDetail: function(pgMerchNo){
				this.$router.push({ to: 'PaymentsHistory', params: { payChnCate: pgMerchNo}})
			}
			,clear: function(){
				this.$set(this.search, 'keyword', '')
				this.$set(this.search, 'status', '')
				this.getData()
			}
			,excel: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/settlements/merchants/download'
						,data: {
							inquiryStartDate: date.getSearchBaseDate()
						}
						,authorize: true
					})

					if(result.success){
						this.excel_items = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.data.message })
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,setSearchDate: async function(type){
				this.dateType = type
				if(type == 'd'){
					this.search.inquiryStartDate = this.today
					this.search.inquiryEndDate = this.today
				}else if(type == 'w'){
					let dates = date.getWeeklyDate()
					this.search.inquiryStartDate = dates.start
					this.search.inquiryEndDate = dates.end
				}else if(type == 'm'){
					let dates = date.getMonthlyDate()
					this.search.inquiryStartDate = dates.start
					this.search.inquiryEndDate = dates.end
				}else{
					this.search.inquiryStartDate = date.getSearchBaseDate()
					this.search.inquiryEndDate = date.getToday()
				}
				
				await this.getData()
			}
			,getDetail: async function(item_index, inquiryDate){
				if(this.items[item_index].details){
					this.$delete(this.items[item_index], 'details')
					return false
				}
				try{
					const result = await Axios({
						method: 'get'
						,url: '/settlement/history'
						,data: {
							inquiryDate: inquiryDate
						}
						,authorize: true
					})
					
					if(result.success){
						this.$set(this.items[item_index], 'details', result.data.content)
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,showPassword: function(index){
				this.showPopup.item = true
				this.item = this.items[index]
				this.$emit('setOverlay', true)
			}
			,showAccount: function(index){
				this.showPopup.account = true
				this.item = this.items[index]
				this.item.index = index
				this.$emit('setOverlay', true)
			}
			,showNew: function(){
				this.showPopup.new = true
				this.$emit('setOverlay', true)
			}
			,setOverlay: function(){
				
				this.showPopup.item = false
				this.showPopup.account = false
				this.showPopup.new = false
				
				this.$emit('setOverlay', false)
			}
			,setNotify: function({ type, message}){
				this.$emit('setNotify', { type: type, message: message })
			}
			,setAccount: function(item){
				this.$set(this.items[item.index], 'status', item.status)
				this.$set(this.items[item.index], 'statusName', item.statusName)
				this.setOverlay()
			}
			,clipboardSuccessHandler () {
				this.$emit('setNotify', { type: 'success', message: '회원가입 URL이 복사되었습니다'})
			}
			,clipboardErrorHandler () {
			}
		}
		,created: async function(){
			this.clear()
		}
		,watch: {
			'search.page': {
				handler: function(){
					this.getData()
				}
			}
			,'search.inquiryStartDate': {
				handler: function(call){
					this.search.inquiryStartDate = call.replaceAll('-', '')
				}
			}
			,'search.inquiryEndDate': {
				handler: function(call){
					this.search.inquiryEndDate = call.replaceAll('-', '')
				}
			}
		}
	}
</script>

<style>
	.sales .table.type07 .td:nth-child(1) { width: 5.5% !important; justify-content: center !important;}
	.sales .table.type07 .td:nth-child(2) { width: 13.5% !important; justify-content: center !important; padding-left: 0 !important; }
	.sales .table.type07 .td:nth-child(3) { width: 13.5% !important; justify-content: center !important;}
	.sales .table.type07 .td:nth-child(4) { width: 13.5% !important; justify-content: center !important;}
	.sales .table.type07 .td:nth-child(5) { width: 13.5% !important; justify-content: center !important;}
	.sales .table.type07 .td:nth-child(6) { width: 13.5% !important; justify-content: center !important;}
	.sales .table.type07 .td:nth-child(7) { width: 13.5% !important; justify-content: center !important; padding-left: 0 !important;}
	.sales .table.type07 .td:nth-child(8) { width: 13.5% !important; justify-content: center !important;}
	
	.bg-gray { background-color: #eee !important;}
</style>





















